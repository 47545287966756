import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Instagram({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Tjäna pengar på Instagram (Guide)"

  return (
    <>
      <Page title={title} description="På Instagram kan man tjäna pengar på sponsrade inlägg från olika företag, affiliatemarknadsföringc eller genom att sälja olika produkter och tjänster. Du kan exempelvis utveckla en egen produkt som du sedan säljer till ditt sociala nätverk.">
      <div className="w-full py-3 bg-gray-100"><p className="flex items-center px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto max-w-screen-xl justify-between w-full">Inlägget innehåller reklam genom annonslänkar.</p></div>

        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="sociala medier" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>Många använder endast Instagram för att konsumera innehåll, men det går också att tjäna pengar på plattformen om du är villig att lägga ner arbetet för att växa ditt konto.</p>
            <p>I denna artikeln går vi igenom hur du kan växa ditt Instagramkonto och börja tjäna pengar på det.</p>
            <h2 id="hur-kan-man-tj-na-pengar-p-instagram-">Hur kan man tjäna pengar på Instagram?</h2>
            <p>Det finns flera olika sätt att tjäna pengar på Instagram, men det vanligaste sättet är helt klart genom sponsrade inlägg från olika företag. Två andra sätt som är vanliga är också genom affiliatemarknadsföring och genom att sälja olika produkter och tjänster.</p>
            <p>Dessa olika sätten att skapa inkomster skiljer sig en hel vi kommer därför att gå igenom dem mer i detalj nedan.</p>
            <h3 id="sponsrade-samarbeten">Sponsrade samarbeten</h3>
            <p>För sponsrade samarbeten tjänar du pengar genom att samarbete med företag eller andra kreatörer. Vanligtvis blir du betald genom att att <strong>rekommendera företaget tjänster eller produkter</strong>.</p>
            <p>Om du har ett större konto på Instagram är det vanligt att företagen själva hör av sig till dig för att fråga om ett samarbete, men du kan också kontakta företagen direkt och fråga om de är intresserade av ett betalt samarbete.</p>
            <p>Hur mycket du kan tjäna på ett sponsrade samarbeten beror på <strong>hur många följare du har</strong> och hur många användare som ser dina inlägg, för det är det som företagen är intresserade av. Företagen vill nå ut till så många potentiella kunder som möjligt.</p>
            <p>Om du inte har så många följare ännu kan du fortfarande få sponsrade partnerskap, men du kommer inte att kunna ta ut lika mycket pengar.</p>
            <p>Vanligtvis kan man räkna med att tjäna omkring <strong>300-700 CPM</strong>, där CPM betyder antalet kronor per tusen personer/visningar. Om du exempelvis har 7000 följare, innebär detta att du ta betalt nånstans mellan 2100-4900 kronor.</p>
            <p>När du tar betalt från ett samarbete ska du alltid lägga till moms (vilket är 25% för de flesta tjänster som influenser erbjuder).</p>
            <p>Om du inte har ett eget företag när du skall fakturera kunden kan du använda dig en egenanställningstjänst såsom Cool Company för att fakturera. Däremot rekommenderar jag att starta eget företag eller en enskild firma.</p>
            <h3 id="affilatemarknadsf-ring">Affilatemarknadsföring</h3>
            <p>Affilatemarknadsföring innebär att du tjänar pengar genom att rekommendera olika produkter och tjänster. Om du till exempel har en Instagram om mode kan du rekommendera olika kläder och accessoarer från olika nätbutiker.</p>
            <p>Om någon klickar på länken som du har delat och köper något från butiken får du en provision för vad de har köpt. Storleken på provisionen varierar från butik till butik, men den brukar ligga runt 5-10 %.</p>
            <p>Om du vill använda dig av affilatemarknadsföring som inkomstkälla så behöver du registrera dig på ett affilatenätverk såsom <a href="https://click.adrecord.com/?c=44861&p=120" rel="nofollow" target="_blank">Adrecord</a>.</p>
            <p>Något som du behöver tänka på när du rekommenderar produkter är att endast rekommendera produkter som du själv gillar och använder. Annars blir det mycket svårt att sälja produkten till andra.</p>
            <h3 id="att-s-lja-produkter-och-tj-nster">Att sälja produkter och tjänster</h3>
            <p>Om du har en stor publik kan du <strong>sälja produkter och tjänster</strong> till dem. Om du till exempel har en Instagramsida som handlar om skönhet kan du sälja dina egna smink- eller hudvårdsprodukter.</p>
            <p>Du kan också sälja digitala produkter som <strong>e-böcker eller kurser</strong>. Om du har kunskap som andra tycker är värdefull finns det en god chans att de är villiga att betala för den.</p>
            <p>Fördelen med att sälja produkter eller tjänster är att du får behålla alla pengar du tjänar, i motsats till att bara få en provision.</p>
            <p>För att sälja produkter på Instagram måste du också ha en e-handelslösning som Shopify eller Woocommerce. Dessa plattformar gör det enkelt att inrätta en nätbutik och börja sälja produkter.</p>
            <p>En nackdel med att sälja produkter är också att du vanligtvis kan behöva en större publik för att få tillräckligt med försäljning för att kunna leva på. Detta beror på att varje enskild försäljning vanligtvis inte är så hög.</p>
            <h2 id="hur-mycket-kan-man-tj-na-p-instagram-">Hur mycket kan man tjäna på Instagram?</h2>
            <p>Hur mycket du kan tjäna på Instagram är väldigt svårt att säga eftersom det beror på så många olika faktorer.</p>
            <p>Några av de sakerna så påverkar hur mycket du kan tjäna på Instagram är:</p>
            <ul>
              <li>hur många följare du har</li>
              <li>hur aktiva de är</li>
              <li>vilken nisch du befinner dig i</li>
              <li>vilka typer av inkomstkällor du har</li>
            </ul>
            <p>Först och främst så påverkar antalet följare och hur aktiva de är hur mycket du kan tjäna. Om du har många följare men de är inte särskilt aktiva (dvs. de engagerar sig inte i ditt innehåll) kan du vanligtvis inte tjäna lika mycket på sponsring eller produktförsäljning.</p>
            <p>Anledningen är att företag vill nå ut till så många potentiella kunder som möjligt och om dina följare inte är aktiva är de <strong>inte särskilt värdefulla</strong> för företagen.</p>
            <p>Vilken <strong>nisch du befinner</strong> dig i är också en stor faktor. Om du befinner dig i en nisch med mycket konkurrens kan det vara svårare att få sponsring eller produktförsäljning. Detta beror på att det finns vanligtvis många influencers som konkurrerar om samma möjligheter.</p>
            <p>Slutligen påverkar också vilka typer av inkomstkällor du har hur mycket du kan tjäna på Instagram. Om du bara har en inkomstkälla (t.ex. endast sponsring) kan du vanligtvis inte tjäna lika mycket som om du har flera inkomstkällor (t.ex. sponsring, produkt försäljning, affiliatemarknadsföring).</p>
            <p>Det beror på att det är mer sannolikt att du får möjligheter om du har flera inkomstkällor.</p>
            <p>Sammanfattningsvis beror hur mycket du kan tjäna på Instagram på många olika faktorer. Det är inte möjligt att ge ett definitivt svar eftersom det finns för många variabler. Det är dock möjligt att göra en bra inkomster från Instagram om du har en stor och engagerad följarskara och om du har flera inkomstkällor.</p>
            <GatsbyImage image={data.image2.childImageSharp.gatsbyImageData} className="rounded-xl" alt="instagram statistik" />
            <h2 id="vad-r-nackdelar-och-f-rdelar-med-instagram-">Vad är nackdelar och fördelar med Instagram?</h2>
            <p>Det finns både <strong>fördelar och nackdelar</strong> med att använda Instagram som inkomstkälla.</p>
            <p>Fördelarna är att det vanligtvis är rätt så enkelt för att komma igång och skapa ett konto. Allt du behöver är en telefon eller surfplatta och Instagram-appen</p>
            <p>Det är också ett utmärkt sätt att komma i kontakt med människor och dela dina intressen med andra. Och om du är duktig på det kan du tjäna en hyfsad summa pengar på det.</p>
            <p>Nackdelarna är att det kan vara ganska tidskrävande att producera <strong>högkvalitativa innehåll</strong> och att det tar lång tid att växa ett stort instagramkonto om man inte har någon tidigare erfarenhet av det. Det finns också mycket konkurrens där ute, så det kan vara svårt att sticka ut från mängden.</p>
            <p>När du byggt upp ett större Instagramkonto kan det däremot fungera som en passiv inkomst där du inte behöver göra så mycket jobb föra att tjäna lite extra pengar, vilket är en stor fördel. Du behöver däremot lägga ut inlägg kontinuerligt för att att se något resultat men det behöver ändå inte vara så tidskrävande senare.</p>
            <h2 id="hur-kan-man-v-xa-sitt-instagram-konto-">Hur kan man växa sitt Instagram-konto?</h2>
            <p>För att kunna <strong>tjäna pengar på Instagram</strong> behöver du först och främst växa ditt konto så att du får en stor och engagerad publik</p>
            <p>Nedan går vi igenom några olika sätt att göra detta på:</p>
            <ol>
              <li>Använd populära hashtaggar så att dina bilder dyker upp när folk söker efter dessa taggar.</li>
              <li>Gilla och kommentera andras foton regelbundet. Detta ger dig mer exponering och hjälper dig också att få vänner på Instagram.</li>
              <li>Publicera foton och videor av hög kvalitet som är intressanta och visuellt tilltalande.</li>
              <li>Använd Instagram Stories för att ge dina följare en titt bakom kulisserna på ditt liv eller ditt företag.</li>
              <li>Använd dig av Reels för att nå ut till fler.</li>
              <li>Samarbeta med andra Instagram-användare som har en liknande målgrupp som din.</li>
              <li>Lägg upp nya foton kontinuerligt.</li>
            </ol>
            <p>Detta var en snabb guide över några sätt att växa ditt Instagram-konto och börja tjäna pengar.</p>

            <br />
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "instagram.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "insta-stats.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
